<template>
  <div style="width: 100%;padding: 10px">
    <el-button @click="dialogVisible = true">添加配置</el-button>
    <el-table
        :data="tableDataShow"
        border
        style="width: 100%">
      <el-table-column
          fixed="left"
          label="配置名"
          prop="name"
          width="100">
      </el-table-column>
      <el-table-column
          fixed="left"
          label="产品"
          prop="parentId"
          width="100">
        <template slot-scope="scope">
          <span>{{getNameById(scope.row.parentId)}}</span>
        </template>
      </el-table-column>
      <el-table-column
          label="原料"
          prop="children"
          width="130">
        <template slot-scope="scope">
          <span>{{getMaterialNameByIds(scope.row.children)}}</span>
        </template>
      </el-table-column>
      <el-table-column
          label="原料数"
          prop="cost"
          width="130">
      </el-table-column>
      <el-table-column
          label="操作"
          width="100">
        <template slot-scope="scope">
          <el-button size="small" type="text" @click="up(scope.row)">编辑</el-button>
          <el-popconfirm title="确定删除吗？" @confirm = "deleterow(scope.row.id)">
            <el-button type="text" size="small" slot="reference">删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <div style="margin: 10px 0">
      <el-pagination
          :current-page="currentPage4"
          :page-size="size"
          :page-sizes="[5, 10, 20]"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange">
      </el-pagination>
    </div>


    <!--  编辑弹窗  -->
    <el-dialog :before-close="handleClose" :visible.sync="dialogVisible" width="50%">
      <el-form :model="form" label-width="80px">
        <el-form-item label="配置名">
          <el-input v-model="form.name" placeholder="请输入配置名"></el-input>
        </el-form-item>
        <el-form-item label="型号">
          <el-select
              v-model="form.parentId"
              filterable
              placeholder="请输入关键词"
              :loading="loading">
            <el-option
                v-for="item in xinghaoListTotal"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <div v-for="costItem in costList">
          <el-form-item label="原料种类">
            <el-select
                v-model="costItem.materialType"
                @change="getMaterialList(costItem.materialType)"
                placeholder="请选择原料类别">
              <el-option
                  v-for="item in materialTypeList"
                  :key="item.keyId"
                  :label="item.valueName"
                  :value="item.keyId">
              </el-option>
            </el-select>
            <el-select
                v-show="costItem.materialType !== null && costItem.materialType!==''"
                v-model="costItem.material"
                filterable
                remote
                reserve-keyword
                placeholder="请输入关键词"
                :remote-method="remoteMethod2"
                :loading="loading2">
              <el-option
                  v-for="item in materialList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
            <el-input-number v-show="costItem.materialType !== null && costItem.materialType!==''" v-model="costItem.materialNum" :min="1" label="请选择原料数量"></el-input-number>
          </el-form-item>
        </div>
        <el-button @click="addCost()">添加原料</el-button>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false,materialList = [];">取 消</el-button>
        <el-button type="primary" @click="save()">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>

import request from "@/utils/request";

export default {
  name: "Cost",
  data() {
    return {
      currentPage4: 1,
      size: 10,
      total: 0,
      tableData: [],
      tableDataShow: [],
      dialogVisible:false,
      form:{},
      loading:false,
      loading2:false,
      xinghaoListTotal:[],
      xinghaoList:[],
      costList:[],
      allMaterialListTotal:[],
      materialTypeList:[],
      materialListTotal:[],
      materialList:[],
    }
  },
  //加载表格
  mounted() {
    this.select();
    this.GetXinghaoListTotal();
    this.GetAllMaterialListTotal();
    this.GetMaterialTypeList();
  },
  watch: {
    dialogVisible(newName, oldName) {
      if (newName === false){
        this.costList = []
      }
    }
  },
  methods: {
    //整表查询
    select() {
      request.get("/cmdb-cost/page?pageNum="+this.currentPage4+"&pageSize="+this.size,this.timeSpase).then(res => {
        this.tableDataShow = res.data.records
        this.total = res.data.total
        // this.tableDataShow = this.tableData.slice((this.currentPage4-1)*this.size,this.currentPage4*this.size);
      })
    },

    GetXinghaoListTotal(){
      request.post("/CMDB/getPropertyChildren","产品列表").then(res => {
        this.xinghaoListTotal = res.data
      })
    },

    GetAllMaterialListTotal(){
      request.post("/CMDB/getPropertyChildren","原料列表").then(res => {
        this.allMaterialListTotal = res.data
      })
    },

    GetMaterialTypeList(){
      request.post("/CMDB/getKVProperty","原料列表").then(res => {
        this.materialTypeList = res.data
      })
    },
    getMaterialList(id){
      request.post("/CMDB/getPropertyChildrensById",id).then(res => {
        this.materialListTotal = res.data
      })
    },
    up(row){
      this.form.id = row.id
      this.form.name = row.name
      this.form.parentId = row.parentId
      let _material = row.children.split(",")
      let _materialNum = row.cost.split(",")
      for (let i = 0; i < _material.length; i++) {
        let _item = {
          materialType:"",
          material:_material[i],
          materialNum:parseInt(_materialNum[i])
        }
        this.costList.push(_item)
      }
      this.dialogVisible = true
    },
    addCost(){
      let _item = {
        materialType:"",
        material:"",
        materialNum:1
      }
      this.costList.push(_item)
    },
    deleterow(id){
      request.post("/cmdb-cost/del/"+id).then(res => {
        if (res.code == "200"){
          this.$notify({
            title: '成功',
            message: '删除成功',
            type: 'success',
          });
          this.select();
        }else {
          this.$notify.error({
            title: '错误',
            message: res.code + ":" + res.msg
          });
        }
      })
      this.select()
    },
    save(){
      let _childrenList = this.costList.map(item => {
        return item.material
      })
      let _numList = this.costList.map(item => {
        return item.materialNum
      })
      this.form.children = _childrenList.join(",")
      this.form.cost = _numList.join(",")
      request.post("/cmdb-cost/insertOrUpdate",this.form).then(res => {
        if (res.code == "200"){
          this.$notify({
            title: '成功',
            message: '添加成功',
            type: 'success',
          });
          this.dialogVisible = false
          this.select();
        }else {
          this.$notify.error({
            title: '错误',
            message: res.code + ":" + res.msg
          });
        }
      })
      this.select()
    },
    getNameById(id){
      let cmdb = this.xinghaoListTotal.find(item => item.id === id)
      return cmdb == null ? id : cmdb.name
    },
    getMaterialNameByIds(ids){
      let _ids = ids.split(",")
      let _names = []
      for (let i = 0; i < _ids.length; i++) {
        let _id = parseInt(_ids[i])
        let _cmdb = this.allMaterialListTotal.find(item => item.id == _id)
        _names.push(_cmdb.name)
      }
      return _names.join(",")
    },

    handleSizeChange(val) {
      this.size = val;
      this.select()
    },
    handleCurrentChange(val) {
      this.currentPage4 = val
      this.select()
    },
    handleEdit(row) {
    },

    handleClose(done) {
      this.$confirm('确认关闭？')
          .then(_ => {
            this.costList = []
            done();
          })
          .catch(_ => {
          });
    },
    remoteMethod(query) {
      if (query !== '') {
        this.loading = true;
        this.xinghaoList = this.xinghaoListTotal.filter(item => {
          return item.name !== null && item.name !== undefined && (item.name.toLowerCase()
              .indexOf(query.toLowerCase()) > -1);
        });
        this.$nextTick(()=>{
          this.loading = false;
        })
      } else {
        this.xinghaoList = [];
      }
    },
    remoteMethod2(query) {
      if (query !== '') {
        this.loading2 = true;
        this.materialList = this.materialListTotal.filter(item => {
          return item.name !== null && item.name !== undefined && (item.name
              .indexOf(query) > -1);
        });
        this.$nextTick(()=>{
          this.loading2 = false;
        })
      } else {
        this.materialList = [];
      }
    },
  }
}






</script>

<style scoped>

</style>